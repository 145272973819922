<template>
  <div>
    <div class="facilityBox">
      <el-tabs v-model="activeName">
        <el-tab-pane label="待审核" name="1">
          <auditService ref="auditService" v-if="activeName == 1"></auditService>
        </el-tab-pane>
        <el-tab-pane label="待设置" name="2">
          <notSetService ref="notSetService" v-if="activeName == 2"></notSetService>
        </el-tab-pane>
        <!-- <el-tab-pane label="已设置" name="3">
          <setService ref="setService" v-if="activeName == 3"></setService>
        </el-tab-pane> -->
        <el-tab-pane label="待同意" name="4">
          <notAgreeService ref="notAgreeService" v-if="activeName == 4"></notAgreeService>
        </el-tab-pane>
        <el-tab-pane label="已同意" name="5">
          <agreeService ref="agreeService" v-if="activeName == 5" :type="'agree'"></agreeService>
        </el-tab-pane>
        <el-tab-pane label="已合作" name="7">
          <agreeService ref="agreeService" v-if="activeName == 7" :type="'cooper'"></agreeService>
        </el-tab-pane>
        <el-tab-pane label="审核失败" name="6">
          <auditService ref="auditService" :auditError="true" v-if="activeName == 6"></auditService>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import auditService from "@/views/platform/auditInfo/auditService/noAuditService";
import setService from "@/views/platform/auditInfo/auditService/setService";
import notSetService from "@/views/platform/auditInfo/auditService/noSetService";
import agreeService from "@/views/platform/auditInfo/auditService/agreeService";
import notAgreeService from "@/views/platform/auditInfo/auditService/noAgreeService";
export default {
  data() {
    return {
      activeName: "1",
    };
  },
  components: {
    auditService,
    setService,
    notSetService,
    agreeService,
    notAgreeService,
  },
  watch: {
    activeName(val) {
      setTimeout(() => {
        if (val == 1 || val == 6) {
          this.$refs.auditService.GetEnterprise();
        }
        if (val == 2) {
          this.$refs.notSetService.BySupplierLists();
        }
        if (val == 3) {
          this.$refs.setService.BySupplierList();
        }
        if (val == 4) {
          this.$refs.notAgreeService.BySupplierLists();
        }
        if (val == 5) {
          this.$refs.agreeService.BySupplierLists();
        }
        if (val == 7) {
          this.$refs.agreeService.BySupplierLists();
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
